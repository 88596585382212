import * as React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

const CCPA = () => {
  return (
    <Layout>
      <Helmet>
        <title>Hyloq | CCPA</title>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://imgix.cosmicjs.com/e49a2c70-14c7-11ed-b0e6-bf31569d7c90-ICON-TRANS-03.png"
        ></link>
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://hyloq.com/"
        ></link>
        <meta data-react-helmet="true" charset="utf-8"></meta>
        <meta
          data-react-helmet="true"
          name="description"
          content="Hyloq is the first true-scope geofencing advertising platform, Geofenced ads matched with unparalled data feedback for effectkive, hyperlocal marketing."
        ></meta>
        <meta
          data-react-helmet="true"
          name="robots"
          contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:locale"
          content="en-US"
        ></meta>
        <meta data-react-helmet="true" property="og:type" content="page"></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Hyloq | True-Scope Geofencing Advertising Platform"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Hyloq is the first true-scope geofencing advertising platform. Geofenced ads matched with unparalled data feedback for effective, hyperlocal marketing."
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://hyloq.com/"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Hyloq | True-Scope Geofencing Advertising Platform"
        ></meta>
        <meta
          data-react-helmet="true"
          property="article:tag"
          content="geofencing , geofencing advertising , true-scrope"
        ></meta>
        <meta
          data-react-helmet="true"
          property="face-domain-verification"
          content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
        ></meta>
        <link
          href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
          rel="stylesheet"
        ></link>
        <script
          src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
          async
        ></script>
      </Helmet>

      <div className="relative bg-white">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-20 relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:leading-10 pb-10">
                HYLOQ Statement of California Consumer Privacy Act (CCPA)
                Compliance
              </h3>
            </div>
            <div className="pt-6">
              <p class="text-gray-500">
                At Hyloq (“HYLOQ”), we take your privacy seriously. HYLOQ’s
                general privacy policies are published on its website at
                Hyloq.com/privacy-policy. This Statement of CCPA Compliance (the
                “Statement”) provides additional information regarding how HYLOQ
                complies with the California Consumer Privacy Act (CCPA). If you
                are a HYLOQ customer, by using or accessing our products or
                services you acknowledge that you accept the practices and
                policies outlined in this Statement. For California-based
                consumers, this Statement provides the information for you to
                exercise your privacy rights under the CCPA.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                About Personal Information and Privacy
              </h1>
              <p class="text-gray-500">
                As part its business operations, HYLOQ obtains personal consumer
                information (hereafter, ‘Personal Data’) as this is defined by
                the California Consumer Privacy Act (CCPA) and other similar
                privacy laws. HYLOQ does not itself collect consumer Personal
                Data. All such information obtained, stored, and used by HYLOQ
                is derived through contractual relationships with third-party
                vendors. These third-party vendors collect the Personal Data and
                provide it to HYLOQ. HYLOQ provides access to some of this
                information to its customers.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                HYLOQ’s Sources of Consumer Personal Information
              </h1>
              <p class="text-gray-500">
                At present, HYLOQ obtains consumer Personal Data from the
                following third-party vendors:
              </p>
              <br />
              <p class="ml-20 text-gray-500">
                • FullContact Inc. (www.fullcontact.com)
              </p>
              <p class="ml-20 text-gray-500">
                • IPONWEB Limited (www.iponweb.com)
              </p>
              <p class="ml-20 text-gray-500">• Veraset LLC (www.veraset.com)</p>
              <br />
              <p class="text-gray-500">
                Any consumer Personal Data in the possession of HYLOQ and
                provided to its users is the exclusive, proprietary property of
                these third-party vendors. Consequently, requests from consumers
                to exercise their privacy rights regarding this Personal Data
                under the CCPA and other such laws—e.g. to obtain a summary of
                their Personal Data, a copy of the information, to have that
                information deleted, and/or to opt out of the sale of their
                Personal Data—can only be fulfilled by the third-party vendors
                who provide HYLOQ this information. In this Statement we have
                included links to web pages of these third-party vendors so that
                California consumers may exercise these rights.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                What this Statement Covers
              </h1>
              <p class="text-gray-500">
                This Statement covers how we treat Personal Data that we obtain
                from our third-party vendors. “Personal Data” means any
                information that identifies or relates to a particular
                individual and also includes information referred to as
                “personally identifiable information” or Personal Data under
                applicable data privacy laws, rules, or regulations.
              </p>
              <p class="text-gray-500">
                HYLOQ’s Statement of CCPA Compliance does not cover the
                practices of companies we don’t own or control or people we
                don’t manage, such as the third-party vendors from whom we
                obtain consumer Personal Data referenced above.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Categories of Personal Data We Use
              </h1>
              <p class="text-gray-500">
                The following chart details the categories of Personal Data that
                we have obtained, used, and provided to our customers over the
                past twelve (12) months. Throughout this Statement, we will
                refer back to the categories of Personal Data listed in this
                chart (for example, “Category A. Personal identifiers”).
              </p>
              <table class="mt-10 table-fixed  tftable" border="1">
                <tr>
                  <th class="font-semibold w-1/2 px-4 py-6">
                    Category of Personal Data
                  </th>
                  <th class="font-semibold px-4 py-6">
                    Personal Data Collected
                  </th>
                  <th class="font-semibold px-2 py-2">
                    What is the source of this Personal Data?
                  </th>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      A. Personal identifiers
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Real name, alias, postal address, unique
                      personal identifier, online identifier, Internet Protocol
                      address, email address, account name, Social Security
                      number, driver's license number, passport number or other
                      similar identifiers.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Unique personal identifier, online identifier, IP address,
                      email address.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      B. Customer records identified by state law
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      (Including the California Customer Records statute (Cal.
                      Civ. Code 1798.80(e))) Name, signature, Social Security
                      number, physical characteristics or description, address,
                      telephone number, passport number, driver's license or
                      state identification card number, insurance policy number,
                      education, employment, employment history, bank account
                      number, credit card number, debit card number or any other
                      financial information, medical information or health
                      insurance information.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      We do not obtain or use this category of Personal Data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      C. Protected classification characteristics under state or
                      federal law
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Age (40 years or older), race, color, ancestry,
                      national origin, citizenship, religion or creed, marital
                      status, medical condition, physical or mental disability,
                      sex (including gender, gender identity, gender expression,
                      pregnancy or childbirth and related medical conditions),
                      sexual orientation, veteran or military status or genetic
                      information (including familial genetic information).
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Age, race, gender.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      D. Commercial information
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Records of personal property, products or
                      services purchased, obtained, or considered, or other
                      purchasing or consuming histories or tendencies.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      We do not obtain or use this category of Personal Data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      E. Biometric information
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Genetic, physiological, behavioral, and
                      biological characteristics or identifying activity
                      patterns, such as fingerprints, faceprints, and
                      voiceprints, iris or retina scans, keystroke, gait, or
                      other physical patterns, and sleep, health or exercise
                      data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      We do not obtain or use this category of Personal Data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      E. Biometric information
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Genetic, physiological, behavioral, and
                      biological characteristics or identifying activity
                      patterns, such as fingerprints, faceprints, and
                      voiceprints, iris or retina scans, keystroke, gait, or
                      other physical patterns, and sleep, health or exercise
                      data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      We do not obtain or use this category of Personal Data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      F. Internet or other similar network activity information
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Browsing history, search history, or information
                      on a consumer's interaction with a website, application or
                      advertisement.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Browsing history, search history, interaction with a
                      website, application, or advertisement.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      G. Geolocation data
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Physical location or movements.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Physical location and movements.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">H. Sensory data</p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Audio, electronic, visual, thermal, olfactory or
                      similar information.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      We do not obtain or use this category of Personal Data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      I. Professional or employment-related information
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Current or past job history or performance
                      evaluations.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      We do not obtain or use this category of Personal Data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      J. Non-public education information
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      (per the Family Educational Rights and Privacy Act (20
                      U.S.C. Section 1232g, 34 C.F.R. Part 99)) Education
                      records directly related to a student maintained by an
                      educational institution or party acting on its behalf,
                      such as grades, transcripts, class lists, student
                      schedules, student identification codes, student financial
                      information or student disciplinary records.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      We do not obtain or use this category of Personal Data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      K. Inferences drawn from other personal information
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Examples: Profile reflecting a person's preferences,
                      characteristics, psychological trends, predispositions,
                      behavior, attitudes, intelligence, abilities and
                      aptitudes.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Person’s preferences, psychological trends, behavior,
                      attitudes, intelligence, abilities, and aptitudes.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      L. Age or date of birth
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">Age</p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      M. Special categories of data under the EU General Data
                      Protection Regulation
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      Personal data revealing racial or ethnic origin, political
                      opinions, religious or philosophical beliefs, or trade
                      union membership; genetic data or biometric data processed
                      for the purpose of uniquely identifying a natural person;
                      data concerning health; or data concerning a natural
                      person’s sex life or sexual orientation.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      We do not obtain or use this category of Personal Data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 py-6">
                    <p ClassName="font-medium text-gray-900">
                      N. Personal Data about children under the age of 16
                    </p>
                    <p className="text-sm leading-6 text-gray-500">
                      This includes any type of Personal Data that relates to
                      someone under the age of 16.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      We do not obtain or use this category of Personal Data.
                    </p>
                  </td>
                  <td class="border px-4 py-6">
                    <p className="text-sm leading-6 text-gray-500">
                      Third Parties.
                    </p>
                  </td>
                </tr>
              </table>

              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                How We Use Your Personal Data
              </h1>
              <p class="text-gray-500">
                We use Personal Data to provide services to our clients for the
                following purposes:
              </p>
              <br />
              <p class="ml-20 text-gray-500">
                • To personalize advertisements, offers, and/or website content
                and communications based on your preferences.
              </p>
              <p class="ml-20 text-gray-500">
                • To improve and develop the Services, including testing,
                research, analysis, and product development.
              </p>
              <p class="ml-20 text-gray-500">
                • To protect against or deter fraudulent, illegal, or harmful
                actions and maintain the safety, security, and integrity of our
                Services.
              </p>
              <p class="ml-20 text-gray-500">
                • To comply with our legal or contractual obligations and
                resolve disputes.
              </p>
              <p class="ml-20 text-gray-500">
                • To respond to law enforcement requests and as required by
                applicable law, court order, or governmental regulations.
              </p>
              <p class="ml-20 text-gray-500">
                • For any other business purpose stated as otherwise set forth
                in applicable data privacy laws, such as the California Consumer
                Privacy Act (the “CCPA”).
              </p>
              <br />
              <p class="text-gray-500">
                We will not collect additional categories of Personal Data or
                use the Personal Data we collected for materially different,
                unrelated, or incompatible purposes.
              </p>

              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                How We Share Your Personal Data - Disclosures of Personal Data
                for a Business Purpose
              </h1>
              <p class="text-gray-500">
                We disclose your Personal Data to our clients for the following
                business purposes:
              </p>
              <br />
              <p class="ml-20 text-gray-500">
                • Short-term, transient use of Personal Data that is not used by
                another party to build a consumer profile or otherwise alter
                your consumer experience outside the current interaction.
              </p>
              <p class="ml-20 text-gray-500">
                • Performing services on our behalf, including; maintaining or
                servicing accounts, providing customer service, processing or
                fulfilling orders and transactions, verifying customer
                information, processing payments, providing financing, providing
                advertising or marketing services, providing analytic services,
                or providing similar services on behalf of the business or
                service provider
              </p>
              <p class="ml-20 text-gray-500">
                • Undertaking internal research for technological development
                and demonstration.
              </p>
              <br />
              <p class="text-gray-500">
                HYLOQ discloses Personal Data it obtains from its third-party
                vendors only to its clients. Our clients use that data in
                advertising campaigns and as an analytical tool. HYLOQ has two
                categories of clients who use this data:
              </p>
              <br />
              <p class="ml-20 text-gray-500">
                • Direct Clients: HYLOQ customers who use Personal Data from the
                HYLOQ platform to directly advertise to their own customers.
              </p>
              <p class="ml-20 text-gray-500">
                • Consultants and/or Advertiser Clients: HYLOQ customers who use
                the Personal Data from the HYLOQ system on behalf of their
                clients, who themselves use the HYLOQ platform to directly
                advertise their customers.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                How We Share Your Personal Data - Sales of Personal Data
              </h1>
              <p class="text-gray-500">
                We sell your Personal Data to the following categories of third
                parties:
              </p>
              <br />
              <p class="ml-20 text-gray-500">• Advertisers</p>
              <br />
              <p class="text-gray-500">
                Over the past twelve months, we have sold the following
                categories of your Personal Data to third parties:
              </p>
              <br />
              <p class="ml-20 text-gray-500">•• Personal identifiers.</p>
              <p class="ml-20 text-gray-500">
                •• Customer records identified by state law.
              </p>
              <p class="ml-20 text-gray-500">
                •• Protected classification characteristics under state or
                federal law.
              </p>
              <p class="ml-20 text-gray-500">•• Commercial information.</p>
              <p class="ml-20 text-gray-500">
                •• Internet or other similar network activity information.
              </p>
              <p class="ml-20 text-gray-500">•• Geolocation data.</p>
              <p class="ml-20 text-gray-500">
                •• Professional or employment-related information.
              </p>
              <p class="ml-20 text-gray-500">
                •• Non-public education information.
              </p>
              <p class="ml-20 text-gray-500">
                •• Inferences drawn from other Personal Data.
              </p>
              <p class="ml-20 text-gray-500">•• Age or date of birth.</p>
              <p class="ml-20 text-gray-500">
                •• Special categories of data under the EU General Data
                Protection Regulation.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Data Security and Retention
              </h1>
              <p class="text-gray-500">
                We seek to protect your Personal Data from unauthorized access,
                use and disclosure using appropriate physical, technical,
                organizational, and administrative security measures based on
                the type of Personal Data and how we are processing that data.
                You should also help protect your data by appropriately
                selecting and protecting your password and/or other sign-on
                mechanism; limiting access to your computer or device and
                browser; and signing off after you have finished accessing your
                account. Although we work to protect the security of your
                information in our possessions please be aware that no method of
                transmitting data over the Internet or storing data is
                completely secure. We cannot guarantee the complete security of
                any data we have obtained from our third-party vendors and
                except as expressly required by law, we are not responsible for
                the theft, destruction, loss or inadvertent disclosure of your
                information or content.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Personal Data of Children
              </h1>
              <p class="text-gray-500">
                We do not knowingly collect or solicit Personal Data from
                children under 16. For further details on our privacy policies
                regarding data from children under 16, please see our general
                privacy policy published on the HYLOQ website at
                www.Hyloq.com/privacy-policy.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                California Resident Consumers’ Personal Data Rights
              </h1>
              <p class="text-gray-500">
                If you are a California resident, you have the rights outlined
                in this Statement. As given earlier in this Statement, HYLOQ
                does not directly collect or control your Personal Data for the
                purposes of exercising your rights under the CCPA. If you have
                any questions about this section or any issues regarding your
                rights, please contact HYLOQ at ‘webmaster “at” hyloq.com’.
              </p>
              <br />
              <p class="text-gray-500">
                Under the CCPA, and other similar privacy laws, you have the
                following rights regarding your Personal Data:
              </p>
              <br />
              <p class="ml-20 text-gray-500">
                • To obtain a summary of your Personal Data.
              </p>
              <p class="ml-20 text-gray-500">
                • To obtain a copy of your Personal Data information.
              </p>
              <p class="ml-20 text-gray-500">
                • To have your Personal Data deleted.
              </p>
              <p class="ml-20 text-gray-500">
                • To opt out of the sale of your Personal Data.
              </p>
              <p class="ml-20 text-gray-500">
                • To prevent disclosure of your Personal Data to third parties.
              </p>
              <p class="ml-20 text-gray-500">
                • Any other Personal Data rights as provided for in the
                California Consumer Privacy Act.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Exercising Your Personal Data Rights
              </h1>
              <p class="text-gray-500">
                To exercise the rights described above you must contact HYLOQ’s
                third-party vendors, who are the source of any Personal Data
                that HYLOQ may possess. Links to the appropriate webpages for
                each of these vendors are provided below.
              </p>
              <p class="text-gray-500">
                In general, when you submit a request please ensure that (1) it
                provides sufficient information to allow the vendor to verify
                that you are the person about whom they have collected Personal
                Data, and (2) it describes your request in sufficient detail to
                allow them to understand, evaluate, and respond to it. Any
                request that meets both of these criteria will be considered by
                them a “Valid Request.” They may not respond to requests that do
                not meet these criteria. They will only use Personal Data
                provided in a Valid Request to verify you and complete your
                request. You do not need an account to submit a Valid Request.
              </p>
              <p class="text-gray-500">
                Generally, our third-party vendors will work to respond to your
                Valid Request within 45 days of receipt. They will not charge
                you a fee for making a Valid Request unless your Valid
                Request(s) is excessive, repetitive, or manifestly unfounded. If
                they determine that your Valid Request warrants a fee, they
                should notify you of the fee and explain that decision before
                completing your request.
              </p>
              <br />
              <p class="ml-20 font-semibold">
                HYLOQ Third-Party Vendors to Contact to Exercise Your Personal
                Data Rights
              </p>
              <p class="ml-20 text-gray-500">
                • Throtle - www.Throtle.io/data-privacy-manager/
              </p>
              <p class="ml-20 text-gray-500">
                • IPONWEB Limited - www.iponweb.com/ccpa-privacy-center/
              </p>
              <p class="ml-20 text-gray-500">
                • Veraset LLC - www.veraset.com/ccpa-privacy-policy
              </p>
              <br />
              <p class="text-gray-500">
                You must contact one or all of these vendors in order to
                exercise any of the rights enumerated above.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Contact Information
              </h1>
              <p class="text-gray-500">
                If you have any questions or comments about this Privacy Policy,
                the ways in which we collect and use your Personal Data, your
                choices and rights regarding such use, please do not hesitate to
                contact us at ‘webmaster “at” hyloq.com’ or
                www.Hyloq.com/contact.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CCPA;
